button:disabled{
  opacity: 0.6;
}
.btn-success {
  color: white!important;
}
.btn-outline-success {
  &:hover {
    color: white!important;

  }
}
.nav-link{
  padding-left: 0!important;
}
/*

.btn-outline-success {
  &:hover {
    background: #3CA75F !important;
    border-color: transparent !important;
  }
}
*/
