$theme-purple: #6559A9;
$theme-bg: #E2F2E7;
$font-small: 0.75rem;
$font-header: 1.75rem;
$font-header-sub: 1.5rem;
$accordion-button-active-bg: transparentt;
$primary: $theme-purple;
$theme-colors: (
        "primary": $theme-purple,
        "secondary": #6C757D,
        "success": #3CA75F,
        "info": #0DCAF0,
        "warning": #ffc107,
        "danger": #dc3545,
        "light": #f8f9fa,
        "dark": #212529
);

@import '~bootstrap/scss/bootstrap.scss';
body{
  margin: 0;
  padding: 0;
  font-family: Outfit, sans-serif;
  box-sizing: border-box;
}
h1, h2, h3, h4, h5 {
  font-weight: 600;
  position: relative;
  line-height: 1.3;

  span {
    color: $theme-purple !important;
  }
}
.underline {

  &::before {
    content: "";
    position: absolute;
    width: 5%;
    height: 3px;
    background: #8BD59F;
    left: 50%;
    bottom: -5px;
    border-radius: 0 10px 10px 0;

  }
  &::after {
    content: "";
    position: absolute;
    width: 5%;
    height: 3px;
    background: $theme-purple;
    left: 45%;
    bottom: -5px;
    border-radius: 10px 0 0 10px;
    animation: sheen 1s forwards;
  }
}

p {
  color: #6D7783;
}
.theme-bg {
  background: $theme-bg;
}


