@import '../../../utils/variables.scss';

header {
  .header-info {
    background: $theme-bg;
    color: $theme-purple;
    text-align: center;
    font-size: $font-small;
    padding-block: 0.25rem;

    span {
      font-weight: 600;
    }
  }

  .navbar-brand {
    img {
      max-width: 100px;
    }
  }

  .nav-item {
    font-weight: bold;
  }
  .dropdown-item.active, .dropdown-item:active {
    background-color: #e9ecef!important ;
  }
}
