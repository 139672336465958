.parallax-box {
  min-height: 300px;
  color: #fff;
  position: relative;

  &::after {
    content: '';
    background-image: var(--bg);
    background-size: cover;
    background-color: #00000090;
    background-blend-mode: overlay;
    background-attachment: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    z-index: -1;
  }
}
