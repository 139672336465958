.dashboard-sidebar {
  a {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.55);

    &.active {
      //color:

    }
  }
}
