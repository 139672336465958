#cookie-popup {
  box-sizing: border-box;
  position: fixed;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 6px 6px rgb(0 0 0 / 25%);
  font-family: inherit;
  z-index: 999997;
  right: 30px;
  bottom: 30px;
  max-width: 395px;
  background-color: #fff;
  color: #393d4d;
}
