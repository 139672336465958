@import '../../utils/variables.scss';
$post-color: $primary;
$meta-color: $theme-purple;

.blog-list {

  img {
    width: 150px;
  }
  .post-preview>a {
    color: $post-color;
    text-decoration: none;


    &:focus,
    &:hover {
      text-decoration: none;
      color: $post-color;
    }

    .post-title {
      margin-top: 1.875rem;
      margin-bottom: 0.625rem;
    }

    .post-subtitle {
      font-weight: 300;
      margin-bottom: 0.625rem;
      font-size: 1rem;
      color: rgba($color: #999, $alpha: 1)
    }

    .post-meta {
      font-style: italic;
      margin-top: 0;
      color: $meta-color;

      >a {
        text-decoration: none;
      }
    }
  }
}

@media (max-width: 992px) {

  .blog-list {
    img {
      width: 100%;
    }

    .post-preview>a {
  
      .post-title {
        margin-top: 1rem;
        margin-bottom: 0.2rem;
      }
    }
  }
}