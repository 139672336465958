@import '../../../utils/variables.scss';
$post-color: $primary;
$meta-color: $theme-purple;

.recent-post li {
    text-decoration: none;

    a {
        color: $post-color;
        text-decoration: none;

        &:focus,
        &:hover {
            text-decoration: none;
            color: $post-color;
        }

        .post-title {
            margin-top: 1.875rem;
            margin-bottom: 0.625rem;
        }


        .post-meta {
            font-style: italic;
            margin-top: 0;
            color: $meta-color;

            >a {
                text-decoration: none;
            }
        }
    }
}

@media (min-width: 992px) {
    // Add styles for wider screens here
}