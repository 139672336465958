.Label {
  font-weight: 500 !important;
  color: #000 !important;
}

.template-section {
  .feature {
    cursor: pointer;
  }
  .feature.active {
    >div   {
      border: 2px solid var(--bs-success);
    }
  }
}
