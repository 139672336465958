@mixin arrow() {
  position: absolute;
  border: solid #999;
  border-width: 0 5px 5px 0;
  display: inline-block;
  padding: 5px;
  top: 40%;
  cursor: pointer;
  transition: all 300ms ease-in;
  &:hover {
    border-color: var(--bs-primary);
  }
}

.testimonial-container {
  overflow: hidden;
  .testimonials-list {
    position: relative;
    .testimonial {
      transform: translateX(attr(translate));
      transition: all 500ms linear;
      animation-direction: alternate;
      height: 0;
      opacity: 0;
      &.active {
        transform: translateX(0);
        opacity: 1;
        height: inherit;
        width: inherit;
      }
    }

    .arrow-prev {
      @include arrow();
      left: 0;
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
    }

    .arrow-next {
      @include arrow();
      right: 0;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }
  }
}
