.add-card-section {
  height: 0;
  transition: all 300ms ease-in-out;
  overflow: hidden;
  opacity: 0;

  &.active {
    opacity: 1;
    height: auto;
  }
}

.card-tile {
  cursor: pointer;
  padding: 10px 4px;
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  justify-content: center;
  .mark-primary {
    display: none;
  }

  &:hover {
    .mark-primary {
      display: block;
    }
  }

  .card-container {


    .primary-card {
      float: right;
    }

    img {
      max-width: 30px;
    }

    .card-number {
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: 0.56px;
      text-align: left;
      color: var(--bs-primary);
      opacity: 0.9;
    }

    &.no-border {
      border-bottom: none;
    }
  }
}
