.brokerages {
  filter: grayscale(1);
  transition: all 300ms ease-in-out;
  cursor: pointer;
  &:hover {
    transition: all 300ms ease-in-out;
    filter: none;

  }
}
